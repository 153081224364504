.title {
    text-align: center;
}

.button-toggle:active,
.button-toggle:focus {
    box-shadow: none !important;
}

.content {
    padding: 10px;
}

.version {
    margin-top: 10px;
}

.version-header {
    display: flex;
    align-items: center;
}

.version-header span:first-child {
    background-color: #1C84C6;
    padding: 3px 15px;
    border-radius: 8px;
    color: white;
}

.version-header span:last-child {
    margin-left: 15px;
    font-size: 25px;
}

.version-tag {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.version-tag div:first-child,
.version-tag div:last-child {
    height: 1px;
    width: 100%;
    margin: 0 25px;
    border-bottom: 1px solid black;
}

.version-tag span {
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    width: 250px;
    text-align: center;
    font-weight: 700;
}

.version-tag span.new {
    background: #1AB393;
}

.version-tag span.improvement {
    background: #F7AC59;
}

.version-tag span.bug {
    background: #ED5565;
}

.version-tag-content {
    margin-left: 15px;
}

.version-tag-content p {
  margin-left: 20px;
}
