@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
.load-bar {
    border: 2px solid #009EE2;
    transition: width 3s;
}

.load-bar-inverse {
    border: 2px solid gray;
    transition: width 3s;
}
.title {
    text-align: center;
}

.button-toggle:active,
.button-toggle:focus {
    box-shadow: none !important;
}

.content {
    padding: 10px;
}

.version {
    margin-top: 10px;
}

.version-header {
    display: flex;
    align-items: center;
}

.version-header span:first-child {
    background-color: #1C84C6;
    padding: 3px 15px;
    border-radius: 8px;
    color: white;
}

.version-header span:last-child {
    margin-left: 15px;
    font-size: 25px;
}

.version-tag {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.version-tag div:first-child,
.version-tag div:last-child {
    height: 1px;
    width: 100%;
    margin: 0 25px;
    border-bottom: 1px solid black;
}

.version-tag span {
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    width: 250px;
    text-align: center;
    font-weight: 700;
}

.version-tag span.new {
    background: #1AB393;
}

.version-tag span.improvement {
    background: #F7AC59;
}

.version-tag span.bug {
    background: #ED5565;
}

.version-tag-content {
    margin-left: 15px;
}

.version-tag-content p {
  margin-left: 20px;
}

.minw-200{
    min-width: 200px;
}
body {
    height: 100vh;
    background-color: #f3f3f4 !important;
    color: #676a6c !important;
}

#root {
  height: 100%;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.ibox-title {
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 90px 8px 15px;
    min-height: 48px;
    position: relative;
    clear: both;
    border-radius: 2px 2px 0 0;
}

.ibox-title h5 {
    display: inline-block;
    font-size: 18px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: none;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid;
    border-width: 1px;
}

.dropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.flex-1 {
    flex:1 1;
}

.btn-primary{
    background-color: #009EE2 !important;
}

.HideScrollLeftMenu::-webkit-scrollbar {
    display: none;
}

.modal-backdrop {
    z-index: 5000 !important;
}

.modal{
    z-index: 50000 !important;
}

/* Propriedade hover para tela de listagem */
.MuiTableRow-root:hover{
    background-color: #EEEEEE;
}

.hr-line-dashed {
    border-top: 1px dashed #e7eaec;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0;
}

@keyframes App-logo-spin {
    from { opacity:0.5; }
    to { opacity:1; }
  }
